<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">cta: promo banner</div>
        <div class="module-preview--contents">
            <div>
                <div class="font-600 text-lg">{{ getValue('message') }}</div>
            </div>
            <div class="self-center">
                <span class="btn-primary btn-solid">{{ getValue('linkText') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ],
}
</script>
<style scoped>
.module-preview--contents{
    grid-template-columns: 1fr auto;
}
</style>